// @mui
import { Theme } from '@mui/material/styles';
import {
  Box,
  SxProps,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  CircularProgress,
} from '@mui/material';
import Can from 'src/common/lib/Can';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------

type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
  isSelectAll?: boolean;
  isShowCheckbox?: boolean;
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  sx,
  isSelectAll,
  isShowCheckbox = true,
}: Props) {
  const isPassSelectAllProp = isSelectAll !== undefined;
  const isSelectedByRow = rowCount > 0 && numSelected === rowCount;
  return (
    <TableHead sx={sx}>
      <TableRow>
        {onSelectAllRows && (
          <Can do="update" on="all">
            <TableCell padding="checkbox" align={'center'} sx={{ background: 'white' }}>
              {isShowCheckbox ? (
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={isPassSelectAllProp ? isSelectAll : isSelectedByRow}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onSelectAllRows(event.target.checked)
                  }
                />
              ) : (
                <CircularProgress size={'60%'} sx={{ alignSelf: 'center' }} />
              )}
            </TableCell>
          </Can>
        )}

        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              background: 'white',
            }}
          >
            {onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onSort(headCell.id)}
                sx={{ textTransform: 'capitalize' }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
