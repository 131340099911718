import { CustomFile } from 'src/common/components/upload';

export enum EGiftType {
  GIFT = 'GIFT',
  E_VOUCHER = 'E_VOUCHER',
  E_VOUCHER_SHOP_BEEKIDS = 'E_VOUCHER_SHOP_BKIDS',
  E_VOUCHER_SHOP = 'E_VOUCHER_SHOP',
  EV_VITA_CODE = 'EV_VITA_CODE',
}

export enum ETransportType {
  FIRST_SPECIAL = 'FIRST_SPECIAL',
  SECOND_SPECIAL = 'SECOND_SPECIAL',
  SAMPLE = 'SAMPLE',
  EXCHANGE = 'EXCHANGE',
}

export enum TierCode {
  MEMBER = 'MEMBER',
  TITAN = 'TITAN',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  SILVER = 'SILVER',
}
export enum PreOrderStatus {
  PRE_ORDER = 'PRE_ORDER',
  EXCHANGED = 'EXCHANGED',
  CANCEL = 'CANCEL',
}
export interface DynamicData {
  description: string;
  link: string;
  weight: number;
  height: number;
  width: number;
  length: number;
}
export interface DynamicFilter {
  code?: string;
  valueCode?: string;
  name?: string;
  mainCode?: string;
  firstLevelCode?: string;
  firstLevelValueCode?: string;
  secondLevelCode?: string;
  secondLevelValueCode?: string;
}

export interface GiftReservation {
  id: number;
  reservationPoint: number;
  reservationPercent: number;
  limitReservationTime: number;
  discountReservationPoint: number;
  discountReservationPercent: number;
  reservationExpiredDays: number;
  minCoinsForReservation: number;
  maximumReservationQuantity: number;
}

export interface IGiftCreateDto {
  categoryId: number;
  rewardAppName: string;
  status: string;
  description: string;
  tagId: string; // huy hiệu
  sourceGift: string;
  giftType: string;
  selectGift: string;
  transportType: string;
  tierCodes: string[];
  rewardAppGiftCode: string;
  priority: string;
  point: string;
  money: string;
  smsGiftId: string;
  startDate: string | null;
  endDate: string | null;
  expiredDate: string | null;
  expiredTime: string | null;
  activeSmsNotification: boolean;
  codeSCT: string;
  total: string;
  totalBucket: string;
  limitGiftCount: string;
  weight: string;
  length: string;
  width: string;
  height: string;
  image: CustomFile | string;
  covers: CustomFile[];
  isAllowReservation: boolean;
  maximumReservationQuantity: string; // Số lượng tối đa đặt trước
  reservationPeriodDays: string; // Số ngày giới hạn đặt trước
  limitReservationTime: string; // Thời gian giới hạn đặt trước
  discountReservation: string; // Giảm giá khi đặt trước
  allowReservationInCoin: string; // Đk số xu đặt trước
  coinToMinus: string; // Số xu bị trừ
  //
  id?: number;
  images?: string[];
  transportTypeCode?: string;
  name?: string;
  badgeCodes?: string[];
  categoryCode?: string;
  tireCodes?: string[];
  type?: string;
  price?: number;
  hiddenTags?: string[];
  sfNumber?: number;
  expireDate?: Date;
  expireHour?: number;
  sctNumber?: string;
  quantity?: number;
  inventory?: number;
  quantityLimitForBooking?: number;
  purchaseOption?: string;
  quantityReward?: number;
  quantityReservation?: number;
  dynamicData?: DynamicData;
  giftReservation?: GiftReservation;
  dynamicFilter?: DynamicFilter;
}

export interface IGiftForm {
  categoryId: number;
  rewardAppName: string;
  status: boolean;
  description: string;
  tagId: string; // huy hiệu
  sourceGift: string;
  giftType: string;
  selectGift: any;
  transportType: string;
  tierCodes: string[];
  rewardAppGiftCode: string;
  priority: string;
  point: string;
  money: string;
  smsGiftId: string;
  startDate: string | null;
  endDate: string | null;
  expiredDate: string | null;
  expiredTime: string | null;
  activeSmsNotification: boolean;
  codeSCT: string;
  total: string;
  totalBucket: string;
  limitGiftCount: string;
  weight: string;
  length: string;
  width: string;
  height: string;
  image: CustomFile | string;
  covers: CustomFile[] | string[];
  imageFile: CustomFile | null;
  coverFiles: CustomFile[] | null;
  isAllowReservation: boolean;
  maximumReservationQuantity: string; // Số lượng tối đa đặt trước
  reservationPeriodDays: string; // Số ngày giới hạn đặt trước
  limitReservationTime: string; // Thời gian giới hạn đặt trước
  discountReservation: string; // Giảm giá khi đặt trước
  allowReservationInCoin: string; // Đk số xu đặt trước
  coinToMinus: string; // Số xu bị trừ
  //
  id?: number;
  images?: string[];
  transportTypeCode?: string;
  name?: string;
  badgeCodes?: string[];
  categoryCode?: string;
  tireCodes?: string[];
  type?: string;
  price?: number;
  hiddenTags?: string[];
  sfNumber?: number;
  expireDate?: Date;
  expireHour?: number;
  sctNumber?: string;
  quantity?: number;
  inventory?: number;
  quantityLimitForBooking?: number;
  purchaseOption?: string;
  quantityReward?: number;
  quantityReservation?: number;
  dynamicData?: DynamicData;
  giftReservation?: GiftReservation;
  dynamicFilter?: DynamicFilter;
}

export interface IParamsGiftList {
  page: number;
  size: number;
  searchText?: string;
  status?: string;
  tierCodes?: string;
  giftType?: string;
  categoryCode?: string;
}

export interface IGift {
  categoryId: number;
  rewardAppName: string;
  status: string;
  description: string;
  tagId: string; // huy hiệu
  sourceGift: string;
  giftType: string;
  selectGift: any;
  transportType: string;
  tierCodes: string[];
  rewardAppGiftCode: string;
  priority: string;
  point: string;
  money: string;
  smsGiftId: string;
  startDate: string | null;
  endDate: string | null;
  expiredDate: string | null;
  expiredTime: string | null;
  activeSmsNotification: boolean;
  codeSCT: string;
  total: string;
  totalBucket: string;
  limitGiftCount: string;
  weight: string;
  length: string;
  width: string;
  height: string;
  image: CustomFile | string;
  covers: CustomFile[] | string[];
  imageFile: CustomFile | null;
  coverFiles: CustomFile[] | null;
  isAllowReservation: boolean;
  maximumReservationQuantity: string; // Số lượng tối đa đặt trước
  reservationPeriodDays: string; // Số ngày giới hạn đặt trước
  limitReservationTime: string; // Thời gian giới hạn đặt trước
  discountReservation: string; // Giảm giá khi đặt trước
  allowReservationInCoin: string; // Đk số xu đặt trước
  coinToMinus: string; // Số xu bị trừ
  //
  id: number;
  images: string[];
  transportTypeCode?: string;
  name?: string;
  badgeCodes?: string[];
  categoryCode?: string;
  tireCodes?: string[];
  type?: string;
  price?: number;
  hiddenTags?: string[];
  sfNumber?: number;
  expireDate?: Date;
  expireHour?: number;
  sctNumber?: string;
  quantity?: number;
  inventory?: number;
  quantityLimitForBooking?: number;
  purchaseOption?: string;
  quantityReward?: number;
  quantityReservation?: number;
  dynamicData?: DynamicData;
  giftReservation?: GiftReservation;
}

export interface IResGiftList {
  data: IGift[];
  status: number;
  total: 6;
}

export interface IResGiftCreate {
  data: {
    status: number;
    data: IGift;
  };
}

export interface IParamsDeleteGift {
  id: number;
}

export interface IParamsDeleteFilter {
  code: string;
}

export interface IResGiftById {
  response: {
    data: IGift;
  };
}

export interface IGiftEditDto extends IGiftCreateDto {
  id: number;
}

export interface IManageEventParams {
  startDate: Date | null;
  page?: number;
  searchText?: string;
  limit?: number;
  endDate: Date | null;
  searchBy?: string | boolean;
  status?: string | boolean;
}

export type Category = {
  value: number;
  label: string;
};

export type GotItCategory = {
  categoryId: number;
  categoryNm: string;
  categoryImg: string;
  slug: string;
  order: number;
};

export type Brand = {
  brandId: number;
  brandNm: string;
  brandImg: string;
  slug: string;
  shortDesc: string;
  description: string;
  categoryID: number[];
  sort: number;
  serviceGuide: null;
};

export type StateProps = {
  categories: Category[];
  keySearch: string;
  categoryId: string;
  type: string;
  active: string;
  tierCode: string;
  gotItBrands: Brand[];
  gotItCategories: GotItCategory[];
};

export interface Size {
  priceId: number;
  priceNm: string;
  priceValue: number;
}

export interface GotItProduct {
  productId: number;
  productNm: string;
  productImg: string;
  brandId: number;
  brandNm: string;
  categoryId: string;
  categoryNm: string;
  size: Size[];
  productDesc: null;
  terms: null;
  storeList: any[];
  link: string;
  productType: string;
}

export interface BKidCourse {
  id: string;
  name: string;
  class: string[];
  price: number;
  type: string;
}

export interface EVCard {
  id: number;
  amount: number;
  point: number;
  eproductName: string;
  eproductId: number;
  priceId: number;
}

export interface EVGotIt {
  id: number;
  amount: number;
  point: number;
  eproductName: string;
  eproductId: number;
  priceId: number;
}

export interface GiftCategory {
  description: string;
  createdAt?: Date;
  updatedAt?: Date;
  id: number;
  code: string;
  isActive: boolean;
  priority: number;
  dynamicData: DynamicDataCategory;
  name: string;
}

export interface Filter {
  code: string;
  isActive: boolean;
  priority: number;
  name: string;
  value: any;
}

export interface DynamicDataCategory {
  description?: null;
  name: string;
  updatedById?: null;
  label?: null;
  labelCode?: null;
  color?: null;
  icon?: null;
}

export interface IParamsGiftCategoriesList {
  page: number;
  size: number;
  searchText?: string;
}
export interface IResGiftCategoryList {
  data: GiftCategory[];
  status: number;
  total: number;
}

export interface IResFilterList {
  data: Filter[];
  status: number;
  // total: number;
}
export interface IGiftCategoryEditDto extends IGiftCategoryCreateDto {
  id?: number;
}

export interface IGiftCategoryCreateDto {
  name: string;
  isActive: boolean;
  priority: number;
  code: string;
  dynamicData?: DynamicDataCategory;
}
export interface IGiftCategoryForm {
  name: string;
  priority: string;
  isActive: boolean;
  code: string;
  dynamicData?: DynamicDataCategory;
}

export interface IResGiftCategoryCreate {
  data: {
    status: number;
    data: GiftCategory;
  };
}

export interface IParamsGiftPreOrderList {
  page: number;
  size: number;
  searchText?: string;
  preOrderStatus?: string;
  giftTypes?: string;
  statuses?: string;
}

export interface IResGiftPreOrderList {
  data: {
    data: any[];
    total: number;
  };
  status: number;
  total: number;
}

export interface IGiftUpdateQuantity {
  quantity?: number;
  inventory?: number;
  quantityLimitForBooking?: number;
}
