import { useState } from 'react';
import { Container } from '@mui/material';
import Page from 'src/common/components/Page';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import useSettings from 'src/common/hooks/useSettings';
import useTable from 'src/common/hooks/useTable';
import { IParamsCoinList } from '../common/interface';
import { useGetCoinList } from '../common/hooks/useGetCoinList';
import CoinListHeader from './components/CoinListHeader';
import CoinListFilter from './components/CoinListFilter';
import CoinListTable from './components/table/CoinListTable';

const CoinList: React.FC = () => {
  const { themeStretch } = useSettings();

  const propsTable = useTable();
  const [searchParams, setSearchParams] = useState<IParamsCoinList>({
    page: propsTable.page,
    size: propsTable.rowsPerPage,
    fromDate: '',
    toDate: '',
    deliveryCode: '',
    userId: '',
    tdId: '',
  });

  const [renderCount, setRenderCount] = useState(0);

  const { data, isLoading, refetch } = useGetCoinList({
    ...searchParams,
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });

  const handleSearch = (dataSearch: IParamsCoinList) => {
    propsTable.setPage(0);
    setSearchParams((prev) => {
      return { ...prev, ...dataSearch };
    });
  };

  const handleRefetch = () => {
    setRenderCount((prev) => prev + 1);
    refetch();
  };

  return (
    <Page title={BREADCUMBS.COIN_LIST}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <CoinListHeader searchParams={searchParams} refetch={handleRefetch} />
        <CoinListFilter handleSearch={handleSearch} />
        <CoinListTable
          data={data?.data?.response?.data?.data}
          isLoading={isLoading}
          totalItems={data?.data?.response?.data?.total}
          {...propsTable}
          onChangePage={propsTable.onChangePage}
          renderCount={renderCount}
        />
      </Container>
    </Page>
  );
};

export default CoinList;
