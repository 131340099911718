import { Container } from '@mui/material';
import Page from 'src/common/components/Page';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import useSettings from 'src/common/hooks/useSettings';
import GiftPreOrderListHeader from './components/GiftPreOrderListHeader';
import GiftPreOrderListTable from './components/table/GiftPreOrderListTable';
import { GiftPreOrderTableToolbar } from './components/GiftPreOrderTableToolbar';
import { useEffect, useState } from 'react';
import useTable from 'src/common/hooks/useTable';
import { IParamsGiftPreOrderList } from '../common/interface';
import { useGetGiftPreOrders } from '../common/hooks/useGetGiftPreOrders';

export default function GiftPreOrdersList() {
  const { themeStretch } = useSettings();

  const propsTable = useTable({
    defaultRowsPerPage: 10,
  });
  const [searchParams, setSearchParams] = useState<IParamsGiftPreOrderList>({
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });
  const { data, isLoading, refetch } = useGetGiftPreOrders({
    ...searchParams,
    page: propsTable.page,
    size: propsTable.rowsPerPage,
    preOrderStatus: searchParams.preOrderStatus
      ? searchParams.preOrderStatus
      : 'PRE_ORDER,EXCHANGED,CANCEL',
    giftTypes: 'GIFT',
  });

  const handleSearch = (dataSearch: any) => {
    propsTable.setPage(0);
    setSearchParams(dataSearch);
  };

  useEffect(() => {
    refetch();
    propsTable.setPage(0);
  }, []);

  return (
    <Page title={BREADCUMBS.GIFT_PRE_ORDER}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <GiftPreOrderListHeader />
        <GiftPreOrderTableToolbar handleSearch={handleSearch} />
        <GiftPreOrderListTable
          data={data?.data?.response}
          isLoading={isLoading}
          {...propsTable}
        />
      </Container>
    </Page>
  );
}
