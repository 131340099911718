import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateProps = {
  searchText?: string;
};

export const initialState: StateProps = {
  searchText: '',
};

export const groupTopic = createSlice({
  name: 'groupTopic',
  initialState,
  reducers: {
    setSeacrhParams: (state, action: PayloadAction<StateProps>) => {
      state.searchText = action.payload.searchText;
    },
  },
});

export const { setSeacrhParams } = groupTopic.actions;

export default groupTopic.reducer;
