import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import authLoginReducer from 'src/auth/login/auth.slice';
import loginReducer from 'src/auth/login/login.slice';
import invitationReducer from 'src/shop-invitation/invitationSlice';
import adminReducer from 'src/admin/admin.slice';
import storeAdminReducer from 'src/store-admin/storeAdmin.slice';
import eventPromotionIVReducer from 'src/event-promotion-IV/eventPromotionIV.slice';
import eventReducer from 'src/event/event-history-prize/event.slice';
import listPrizeReducer from 'src/event/list-prize/eventListPrize.slice';
import editEventPrizeSlice from 'src/event/edit-event-prize/editEventPrize.Slice';
import manageEventReducer from 'src/manage-event-quarter-one/manageEvent.slice';
import eventPrizeQ1Reducer from 'src/event-prize-q1/eventPrizeQ1.slice';
import groupEventReducer from 'src/event-q1-groupEvent/groupEvent.slice';
import eventHistoryPrizeReducer from 'src/event-history-prize/eventHistory.slice';
import eventAddCanReducer from 'src/event-add-can/eventAddCan.slice';
import campaignReducer from 'src/campaign-manage/common/slice';
import groupUserManageReducer from 'src/campaign-manage/groupUser-manage/groupUser.slices';
import pickUserReducer from 'src/campaign-manage/groupUser-manage/components/pick-user/slice';
import editCurrentUserReducer from 'src/campaign-manage/groupUser-manage/components/edit-current-user/currentUser.slice';
import exchangePhoneNumberReducer from 'src/webview-exchange-phone-number/common/slice';
import giftReducer from 'src/gift/common/slice';
import filterReducer from 'src/filter/common/slice';
import eventHistoryPrizeReducerV2 from 'src/event-history-prize-v2/slice';
import groupTopicReducer from 'src/campaign-manage/group-topic-manage/common/slice';
import webviewManageReducer from 'src/webview-manage/slice';
import crmTransactionTypeReducer from 'src/crm-transaction-type/slice';
// slices

// ----------------------------------------------------------------------
const rootPersistConfig = {
  key: 'vitadairy',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['authLogin', 'login'],
};

const rootReducer = combineReducers({
  authLogin: authLoginReducer,
  login: loginReducer,
  shop_invitation: invitationReducer,
  storeAdmin: storeAdminReducer,

  historyList: eventReducer,
  listPrize: listPrizeReducer,
  edit_event_prize: editEventPrizeSlice,
  eventHistoryPrizeV2: eventHistoryPrizeReducerV2,

  admin: adminReducer,
  eventPromotionIV: eventPromotionIVReducer,
  manageEvent: manageEventReducer,
  eventPrizeQ1: eventPrizeQ1Reducer,
  groupEvent: groupEventReducer,
  eventHistoryPrize: eventHistoryPrizeReducer,
  eventAddCan: eventAddCanReducer,
  campaign: campaignReducer,
  groupUserManage: groupUserManageReducer,
  pickUser: pickUserReducer,
  editCurrentUser: editCurrentUserReducer,
  exchangePhoneNumber: exchangePhoneNumberReducer,
  gift: giftReducer,
  filter: filterReducer,
  groupTopic: groupTopicReducer,
  webviewManage: webviewManageReducer,
  crmTransactionType: crmTransactionTypeReducer,
});

export { rootPersistConfig, rootReducer };
