import { FC } from 'react';
import { Button } from '@mui/material';
import HeaderBreadcrumbs from 'src/common/components/HeaderBreadcrumbs';
import Iconify from 'src/common/components/Iconify';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import { PATH_DASHBOARD } from 'src/common/routes/paths';

import dayjs from 'dayjs';
import { FORMATE_DATE_NEW_REQ } from 'src/store-admin/constants';
import { exportUserGiftList } from 'src/user-gift/common/service';

const UserGiftListHeader: FC<{
  refetch: any;
  selectedRows: any[];
  searchParams: any;
}> = ({ refetch, selectedRows = [], searchParams }) => {
  const exportFile = (searchData: any) => {
    const data = {
      fromDate: searchData.dateFrom ? searchData.dateFrom : null,
      toDate: searchData.dateTo ? searchData.dateTo : null,
      statuses: searchData.statuses ? searchData.statuses : "",
      userId: searchData.userId ? searchData.userId : "",
      giftId: searchData.giftId ? searchData.giftId : "",
    };
    const response = exportUserGiftList({
      fetchRequest: data,
      isExportAll: false,
    });

    response
      .then((data) => {
        const fileLink = document.createElement('a');
        const blob = new Blob([data?.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        const fileName = `export_usergift_${dayjs().format(FORMATE_DATE_NEW_REQ)}.xlsx`;

        fileLink.href = window.URL.createObjectURL(blob);
        fileLink.download = fileName;
        fileLink.click();
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading={BREADCUMBS.USER_GIFT_LIST}
        links={[
          {
            name: BREADCUMBS.USER_GIFT_MANAGE,
            href: PATH_DASHBOARD.userGiftAdmin.root,
          },
          { name: BREADCUMBS.USER_GIFT_LIST },
        ]}
        action={
          <>
            <Button
              variant="contained"
              startIcon={<Iconify icon={'akar-icons:file'} />}
              onClick={() => {
                exportFile(searchParams);
              }}
            >
              Xuất
            </Button>
          </>
        }
      />
    </>
  );
};

export default UserGiftListHeader;
