import { useState } from 'react';
import { Container } from '@mui/material';
import Page from 'src/common/components/Page';
import { BREADCUMBS } from 'src/common/constants/common.constants';
import useSettings from 'src/common/hooks/useSettings';
import useTable from 'src/common/hooks/useTable';
import UserGiftListHeader from './components/UserGiftListHeader';
import UserGiftListFilter from './components/UserGIftListFilter';
import { useGetUserGiftList } from '../common/hooks/useGetOrderList';

const UserGiftList: React.FC = () => {
  const { themeStretch } = useSettings();
  const propsTable = useTable();
  const [searchParams, setSearchParams] = useState<any>({
    statuses: "",
    dateFrom: null,
    dateTo: null,
    userId: "",
    giftId: "",
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });

  const [renderCount, setRenderCount] = useState(0);
  const [selectedIds, setSelectedIds] = useState<number[]>([]); // Manage selectedIds here

  const { data, isLoading, refetch } = useGetUserGiftList({
    ...searchParams,
    page: propsTable.page,
    size: propsTable.rowsPerPage,
  });

  const handleSearch = (dataSearch: any) => {
    propsTable.setPage(0);
    setSearchParams((prev: any) => {
      return { ...prev, ...dataSearch };
    });
  };

  const handleRefetch = () => {
    setRenderCount((prev) => prev + 1);
    refetch();
  };

  const handleSelectItem = (id: number, checked: boolean) => {
    setSelectedIds((prev) =>
      checked ? [...prev, id] : prev.filter((item) => item !== id)
    );
  };

  return (
    <Page title={BREADCUMBS.ORDER_LIST}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <UserGiftListHeader
          refetch={handleRefetch}
          selectedRows={selectedIds}
          searchParams={searchParams}
        />
        <UserGiftListFilter handleSearch={handleSearch} />
      </Container>
    </Page>
  );
};

export default UserGiftList;
