export const orderStatusList = [
  { label: 'Tất cả', value: 'ALL' },
  {
    label: 'Chờ xử lý',
    value: 'IN_PROCESS',
    style: { color: '#637381', backgroundColor: '#919EAB29' },
  },
  {
    label: 'Đã tiếp nhận',
    value: 'REQUEST_RECEIVED',
    style: { color: '#B78103', backgroundColor: '#FFC10729' },
  },
  {
    label: 'Đang vận chuyển',
    value: 'DELIVERING',
    style: { color: '#0C53B7', backgroundColor: '#1890FF29' },
  },
  {
    label: 'Giao thành công',
    value: 'SUCCESS',
    style: { color: '#229A16', backgroundColor: '#54D62C29' },
  },
  {
    label: 'Giao thất bại',
    value: 'FAILURE',
    style: { color: '#B72136', backgroundColor: '#FF484229' },
  },
];

export const orderDeliveryProviderIdList = [
  { label: 'Tất cả', value: 'ALL' },
  { label: 'Viettelpost', value: 'VTP' },
  { label: 'Giao hàng tiết kiệm', value: 'GHTK' },
];

export const orderTypeList = [
  { label: 'Tất cả', value: 'ALL' },
  {
    label: 'Thường',
    value: 'NORMAL',
    style: { color: '#FFFFFF', backgroundColor: '#00AB55' },
  },
  {
    label: 'Giao lại',
    value: 'REDELIVERY',
    style: { color: '#212B36', backgroundColor: '#DFE3E8' },
  },
  {
    label: 'Manual',
    value: 'MANUAL',
    style: { color: '#FFFFFF', backgroundColor: '#1890FF' },
  },
  {
    label: 'Đã tách',
    value: 'SEPARATED',
    style: { color: '#637381', backgroundColor: '#919EAB29' },
  },
];

export const TABLE_HEAD_ORDER_LIST = [
  // { id: 'checkBox', label: '', align: 'left' },
  { id: 'raOrId', label: 'RA Or ID', align: 'left' },
  {
    id: 'raDate',
    label: 'Ngày tạo RA',
    align: 'left',
  },
  {
    id: 'orderId',
    label: 'Order ID',
    align: 'left',
  },
  {
    id: 'tdId',
    label: 'TD ID',
    align: 'left',
  },
  {
    id: 'customer',
    label: 'Khách hàng',
    align: 'left',
  },
  {
    id: 'mvd',
    label: 'MVD',
    align: 'left',
  },
  {
    id: 'dvvc',
    label: 'ĐVVC',
    align: 'left',
  },
  {
    id: 'dvvcDate',
    label: 'Ngày tạo ĐVVC',
    align: 'left',
  },
  {
    id: 'ttDvvc',
    label: 'TT ĐVVC',
    align: 'left',
  },
  {
    id: 'ttRa',
    label: 'TT RA',
    align: 'left',
  },
  {
    id: 'showFee',
    label: 'Hiện phí ship',
    align: 'left',
  },
  {
    id: 'fee',
    label: 'Phí ship',
    align: 'left',
  },
  {
    id: 'deliveredTime',
    label: 'Ngày giao hàng',
    align: 'left',
  },
  {
    id: 'reDeliveryId',
    label: 'Re Delivery ID by',
    align: 'left',
  },
  {
    id: 'type',
    label: 'Loại đơn',
    align: 'left',
  },
  {
    id: 'quantity',
    label: 'SL quà',
    align: 'left',
  },
];

export const TABLE_HEAD_ORDER_EDIT = [
  { id: 'transactionId', label: 'Transaction ID', align: 'left' },
  {
    id: 'giftName',
    label: 'Tên quà',
    align: 'left',
  },
  {
    id: 'giftCode',
    label: 'Gift Code',
    align: 'left',
  },
  {
    id: 'coinQuantity',
    label: 'Số xu',
    align: 'left',
  },
  {
    id: 'giftQuantity',
    label: 'Số lượng quà',
    align: 'left',
  },

  {
    id: 'quantityDelivered',
    label: 'Số lượng thực nhận',
    align: 'left',
  },
];

export const SUCCESS_UPLOAD_CODE = 200;
