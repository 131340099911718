import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IParamsFilterList, IStateProps } from './interface';

const initialValue: IStateProps = {
  paramsFilter: {
    page: 1,
    limit: 10,
  },
  idDelete: 0,
  isOpenFormDelete: false,
};

const crmTransactionTypeSlice = createSlice({
  name: 'crmTransactionType',
  initialState: initialValue,
  reducers: {
    setParamsFilter: (state, action: PayloadAction<IParamsFilterList>) => {
      state.paramsFilter = action.payload;
    },
    setIdDelete: (state, action: PayloadAction<number>) => {
      state.idDelete = action.payload;
    },
    setIsOpenFormDelete: (state, action: PayloadAction<boolean>) => {
      state.isOpenFormDelete = action.payload;
    },
  },
});

export default crmTransactionTypeSlice.reducer;

export const { setParamsFilter, setIdDelete, setIsOpenFormDelete } =
  crmTransactionTypeSlice.actions;
