import { CustomFile } from 'src/common/components/upload';

export enum ENUM_TYPE_NOTIFICATION {
  ADD_POINT = 'ADD_POINT',
  SPEND_POINT = 'SPEND_POINT',
  RANK = 'RANK',
  TRACK = 'TRACK',
  BIRTHDAY = 'BIRTHDAY',
  INFORMATION = 'INFORMATION',
}

export enum ENUM_TYPE_SENT {
  ALL_USERS = 'ALL_USERS',
  USER_BY_CONDITION = 'USER_BY_CONDITION',
}

export enum TYPE_CONDITION_NOTIFICATION {
  RANK = 'RANK',
  BIRTH_DATE = 'BIRTH_DATE',
  GENDER = 'GENDER',
  REGISTERED_DATE = 'REGISTERED_DATE',
  EVENT_JOINED = 'EVENT_JOINED',
  LAST_CHECK_IN = 'LAST_CHECK_IN',
  GROUP_USER = 'GROUP_USER',
}

export enum TYPE_OPERATOR {
  MORE_THAN = 'MORE_THAN',
  LESS_THAN = 'LESS_THAN',
  EQUAL = 'EQUAL',
  BETWEEN = 'BETWEEN',
  IN = 'IN',
}

export enum ENUM_TYPE_BIRTH {
  DATE = 'DATE',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  FULL_DATE = 'FULL_DATE',
}

export enum ENUM_SCHEDULE_NOTIFICATION {
  IMMEDIATE = 'IMMEDIATE',
  SCHEDULE = 'SCHEDULE',
}

export enum ENUM_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ENUM_GENDER {
  MALE = 'Nam',
  FEMALE = 'Nữ',
}

export enum ENUM_RANK {
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  TITAN = 'TITAN',
  MEMBER = 'MEMBER',
}

export interface ICampaignListFilterSubmit {
  searchText: string;
  condition: { id: string; name: string } | null;
}

export interface ICampaignListFilter {
  searchText?: string;
  condition?: string;
}

export interface IParamsListCampaign {
  page?: number;
  limit?: number;
  searchText?: string;
  notiCampaignConditionType?: string;
}

export interface ICampaign {
  id: number;
  scheduleTime: string;
  sendType: ENUM_SCHEDULE_NOTIFICATION;
  link: {
    mb: string;
    web: string;
  };
  image: {
    id: number;
    url: string;
  };
  name: string;
  status: ENUM_STATUS;
  notiDisplayTemplateParams: {};
  createdDate: string;
  notiDisplayTemplate: {
    id: number;
    name: string;
    createdDate: string;
    content: string;
    title: string;
    desc: string;
    type: string;
  };
  notiCampaignConfigs: {
    id: number;
    configValue: {
      value: {
        data: any;
        from?: string;
        to?: string;
      };
      type?: ENUM_TYPE_BIRTH;
    };
    notiCampaignId: number;
    notiCampaignConfigRuleId: number;
    notiCampaignConfigRule: {
      id: number;
      notiCampaignOperatorId: number;
      notiCampaignConditionId: number;
      notiCampaignCondition: {
        id: number;
        type: TYPE_CONDITION_NOTIFICATION;
        name: string;
      };
      notiCampaignOperator: {
        id: number;
        type: string;
        name: string;
      };
    };
  }[];
  buttonName?: string;
  notiTopicId: number;
}

export interface ICampaignById {
  data: {
    response: ICampaign;
  };
}

export interface IResListCampaign {
  data: {
    response: ICampaign[];
    pagination: {
      totalRecords: number;
      currentPage: number;
      recordsPerPage: number;
      totalPages: number;
      last: boolean;
    };
  };
}

export interface INotifyPushSubmit {
  image: CustomFile | string;
  name: string;
  title: string;
  detailContent: string;
  templateContent: string;
  routeType?: string;
  deep_link: string;
  route: { route: string; name: string };
  buttonName: string;
}

export interface INotifyPush {
  name: string;
  title: string;
  detailContent: string;
  templateContent: string;
  image?: CustomFile | string;
  deep_link: string;
  route: {
    name: string;
    route: string;
  };
  routeType?: string;
  buttonName: string;
}

export interface INotifyConditionSubmit {
  typeSent: string;
  notiTopicId: number;
  [key: string]: any;
}

export interface ICondition {
  id: string;
  operation?: { id: number; name: string; idConfigRule: number; type: TYPE_OPERATOR };
  condition?: { id: number; name: string; type: string };
  [key: string]: any;
}

export interface INotifyScheduleSubmit {
  typeTimeSend: { id: ENUM_SCHEDULE_NOTIFICATION; name: string } | null;
  dateTimeFuture: Date | null;
}

export interface INotifySchedule {
  typeTimeSend?: ENUM_SCHEDULE_NOTIFICATION;
  dateTimeFuture?: Date | null;
}

export interface IParamsNotifyCondition {
  searchText?: string;
  page?: number;
  limit?: number;
}

export interface IDataNotifyCondition {
  id: number;
  type: TYPE_CONDITION_NOTIFICATION;
  name: string;
}

export interface IResNotifyCondition {
  data: {
    response: IDataNotifyCondition[];
    pagination: {
      totalRecords: number;
      currentPage: number;
      recordsPerPage: number;
      totalPages: number;
      last: boolean;
    };
  };
}

export interface IParamsNotifyConfigRule {
  notiCampaignConditionId?: number;
  notiCampaignOperatorId?: number;
  page?: number;
  limit?: number;
}

export interface IDataNotifyConfigRule {
  id: number;
  notiCampaignOperatorId: number;
  notiCampaignConditionId: number;
  notiCampaignCondition: {
    id: number;
    type: TYPE_CONDITION_NOTIFICATION;
    name: string;
  };
  notiCampaignOperator: {
    id: number;
    type: TYPE_OPERATOR;
    name: string;
  };
}

export interface IResNotifyConfigRule {
  data: {
    items: IDataNotifyConfigRule[];
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
  };
}

export interface IParamsGroupUser {
  limit?: number;
  page?: number;
}

export interface IDataGroupUser {
  userAmount: number;
  id: number;
  name: string;
  status: string;
  createdDate: string;
  desc: string;
}
export interface IResGroupUser {
  data: {
    response: IDataGroupUser[];
    pagination: {
      currentPage: number;
      last: boolean;
      recordsPerPage: number;
      totalPages: number;
      totalRecords: number;
    };
  };
}

export interface IDataCreateNotifyCampaign {
  notiCampaignConfigs:
    | {
        notiCampaignConfigRuleId: number;
        configValue: {
          value: {
            data: any;
          };
          type?: string;
        };
      }[]
    | [];
  scheduleTime?: Date | null;
  sendType: string;
  name: string;
  notiTemplateId: number;
  status: string;
}

export interface IDataCreateNotiDisplayTemplate {
  name: string;
  title: string;
  content: string;
  desc: string;
}
export interface IDataEditNotiDisplayTemplate {
  id: number;
  name: string;
  title: string;
  content: string;
  desc: string;
}

export interface IResCreateNotiDisplayTemplate {
  data: {
    id: number;
    name: string;
    createdDate: string;
    content: string;
    title: string;
    desc: string;
    type: string;
  };
}

export interface IDataCreateNotiCampaign {
  sendType: ENUM_SCHEDULE_NOTIFICATION;
  name: string;
  status: ENUM_STATUS;
  notiDisplayTemplateId: number;
  imageId?: number;
  link: {
    mb: string;
    web: string;
  };
  notiDisplayTemplateParams: {};
  notiCampaignConfigs: {
    notiCampaignConfigRuleId: number;
    configValue: {
      value: {
        data: any;
      };
      type?: ENUM_TYPE_BIRTH;
    };
  }[];
  scheduleTime?: string;
  buttonName?: string;
  notiTopicId: number;
}
export interface IDataEditNotiCampaign {
  id: number;
  sendType: ENUM_SCHEDULE_NOTIFICATION;
  name: string;
  status: ENUM_STATUS;
  notiDisplayTemplateId: number;
  imageId?: number;
  link: {
    mb: string;
    web: string;
  };
  notiDisplayTemplateParams: {};
  notiCampaignConfigs: {
    notiCampaignConfigRuleId: number;
    configValue: {
      value: {
        data: any;
      };
      type?: ENUM_TYPE_BIRTH;
    };
  }[];
  scheduleTime?: string;
  buttonName?: string;
  notiTopicId: number;
}

export interface INotiCampaignConfig {
  notiCampaignConfigRuleId: number;
  configValue: {
    value: {
      data: any;
      from?: string;
      to?: string;
    };
    type?: ENUM_TYPE_BIRTH;
  };
  id: number;
}

export interface IDataEditCampaign {
  sendType: ENUM_SCHEDULE_NOTIFICATION;
  name: string;
  status: ENUM_STATUS;
  notiDisplayTemplateId: number;
  imageId?: number;
  link: {
    mb: string;
    web: string;
  };
  notiDisplayTemplateParams: {};
  id: number;
  notiCampaignConfigs: INotiCampaignConfig[];
  scheduleTime: string;
  buttonName?: string;
  notiTopicId: number;
}

export interface ICallback {
  onSuccess: Function;
  onError: Function;
}

export interface ICampaignByIdForm {
  id: number;
  name: string;
  title: string;
  typeNotify: string;
  link: string;
  detailContent: string;
  templateContent: string;
  recipientGroup: string;
  condition: string;
  operation: string;
  groupUser: string;
  location: string;
  lastCheckIn: string;
  birthday: string;
  typeSend: string;
  scheduleTime: string;
  separationCount: string;
  timeSent: string;
  startDate: string;
  endDate: string;
  daysOfMonth: string;
  daysOfWeek: string;
  userId: string;
  status: string;
  buttonName: string;
  notiTopicId: number;
}

export interface ICallback {
  onSuccess: Function;
  onError: Function;
}

export interface ISelectOption {
  value: string | number;
  label: string;
}
