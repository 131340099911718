export const UserGiftStatusList = [
  { label: 'EXCHANGED', value: 'EXCHANGED' },
  {
    label: 'PRE_ORDER',
    value: 'PRE_ORDER',
    style: { color: '#637381', backgroundColor: '#919EAB29' },
  },
  {
    label: 'FAILED',
    value: 'FAILED',
    style: { color: '#B78103', backgroundColor: '#FFC10729' },
  },
  {
    label: 'USED',
    value: 'USED',
    style: { color: '#0C53B7', backgroundColor: '#1890FF29' },
  },
  {
    label: 'PENDING',
    value: 'PENDING',
    style: { color: '#229A16', backgroundColor: '#54D62C29' },
  },
  {
    label: 'EXPIRED',
    value: 'EXPIRED',
    style: { color: '#B72136', backgroundColor: '#FF484229' },
  },
  {
    label: 'IN_PROCESS',
    value: 'IN_PROCESS',
    style: { color: '#637381', backgroundColor: '#919EAB29' },
  },
  {
    label: 'CANCEL',
    value: 'CANCEL',
    style: { color: '#637381', backgroundColor: '#919EAB29' },
  },
  {
    label: 'NEED_REUSE',
    value: 'NEED_REUSE',
    style: { color: '#637381', backgroundColor: '#919EAB29' },
  },
];

export const SUCCESS_UPLOAD_CODE = 200;
