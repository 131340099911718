import { useQuery } from 'react-query';
import { IParamsGiftPreOrderList } from '../interface';
import { QUERY_KEYS } from 'src/common/constants/queryKeys.constant';
import { getGiftPreOrdersV3, getUsers } from '../service';
import { useState } from 'react';

export const useGetGiftPreOrders = (params: IParamsGiftPreOrderList) => {
  const [users, setUsers] = useState<any[]>([]);
  const dataQuery = useQuery(
    [QUERY_KEYS.GIFT_PRE_ORDER_LIST, params],
    async () => {
      const res = await getGiftPreOrdersV3(params);
      const currentUserIds = users.map((user) => user.id);
      const userListIds = (
        Array.from(
          new Set(res.data?.response?.data?.data?.map((item: any) => item.userId)) || []
        ) as number[]
      ).filter((userId: number) => !currentUserIds.includes(userId));
      let newUsers = users;
      if (userListIds.length) {
        const usersResponse = await getUsers({ ids: userListIds.join(',') });
        const usersList = usersResponse?.data?.items;
        newUsers = [...newUsers, ...usersList];
      }
      res.data.response.data.data = res.data?.response?.data?.data?.map((item: any) => ({
        ...item,
        user: newUsers.find((u: any) => u.id === item.userId),
      }));
      setUsers(newUsers);
      return res;
    },
    {
      cacheTime: 1000,
    }
  );
  return { ...dataQuery };
};
