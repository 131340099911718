import {
  Box,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Tooltip,
} from '@mui/material';
import lodash from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from 'src/common/components/table';
import LoadingTableSkeleton from 'src/common/components/LoadingTableSkeleton';
import { useSelectMultiple } from 'src/common/hooks/useSelectMultiple';
import Iconify from 'src/common/components/Iconify';
import GiftPreOrderRow from './GiftPreOrderRow';
import { ConfirmModal } from 'src/common/components/modal/ConfirmModal';
import { FC, useState } from 'react';
import useShowSnackbar from 'src/common/hooks/useMessage';
import { useQueryClient } from 'react-query';
import { replacePathParams } from 'src/common/utils/replaceParams';
import { PATH_DASHBOARD } from 'src/common/routes/paths';
import { TABLE_HEAD_GIFT_PRE_ORDER_LIST } from 'src/gift/common/constant';
import { IResGiftPreOrderList } from 'src/gift/common/interface';

export interface GiftPreOrderListTableProps {
  data: IResGiftPreOrderList | undefined;
  isLoading: boolean;
  dense: boolean;
  order: any;
  page: number;
  setPage: any;
  orderBy: any;
  rowsPerPage: any;
  selected: any;
  setSelected: any;
  onSelectRow: any;
  onSelectAllRows: any;
  onSort: any;
  onChangePage: any;
  onChangeDense: any;
  onChangeRowsPerPage: any;
}

const GiftPreOrderListTable: FC<GiftPreOrderListTableProps> = ({
  data,
  isLoading,
  page,
  rowsPerPage,
  dense,
  onChangeDense,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const navigate = useNavigate();
  const [idsDelete, setIdsDelete] = useState<string[]>([]);

  const { showErrorSnackbar, showSuccessSnackbar } = useShowSnackbar();
  const queryClient = useQueryClient();

  const listGifts: any[] = data?.data?.data || [];
  const totalItems = data?.data?.total || 0;

  const { isCheckedAll, selectedIds, handleSelectItem, handleCheckAll } =
    useSelectMultiple<string>(
      listGifts.map((item) => item.code),
      page + 1
    );

  const handleDeleteMultiple = (selectedIds: string[]) => {
    setIdsDelete(selectedIds);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <TableContainer sx={{ position: 'relative' }}>

        <Table size={dense ? 'small' : 'medium'}>
          <TableHeadCustom
            headLabel={TABLE_HEAD_GIFT_PRE_ORDER_LIST}
            rowCount={listGifts.length}
            isSelectAll={isCheckedAll}
            numSelected={selectedIds.length}
        
          />
          <TableBody>
            {listGifts.map((row, index) => (
              <GiftPreOrderRow
                key={row.id}
                row={row}
                selected={selectedIds.includes(row.code)}
                onSelectRow={(e) => {
                  handleSelectItem(row.code, e);
                }}
              />
            ))}
            {isLoading && (
              <LoadingTableSkeleton
                row={rowsPerPage}
                column={TABLE_HEAD_GIFT_PRE_ORDER_LIST.length + 1}
              />
            )}
            <TableNoData isNotFound={!isLoading && !listGifts?.length} />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={lodash.isEmpty(totalItems) ? (totalItems as number) : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
        <FormControlLabel
          control={<Switch checked={dense} onChange={onChangeDense} />}
          label={'Thu gọn'}
          sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
        />
      </Box>
    </Paper>
  );
};
export default GiftPreOrderListTable;
