import { SyntheticEvent, useState, FC } from 'react';
import { LoadingButton, TabContext, TabList } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  TextField,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider } from 'src/common/components/hook-form';
import Iconify from 'src/common/components/Iconify';
import { FORMAT_DATE_NEWS } from 'src/common/constants/common.constants';
import useTable from 'src/common/hooks/useTable';
import { UserGiftStatusList } from 'src/user-gift/common/constant';

export const initialFilter = {
  statuses: "",
  dateFrom: null,
  dateTo: null,
  userId: "",
  giftId: "",
};

const UserGiftListFilter: FC<{ handleSearch: Function }> = ({ handleSearch }) => {
  const propsTable = useTable();

  const methods = useForm({
    defaultValues: initialFilter,
  });

  const onSubmit = (data: any) => {
    const dateFrom = data.dateFrom
      ? new Date(
          Date.UTC(
            data.dateFrom.getFullYear(),
            data.dateFrom.getMonth(),
            data.dateFrom.getDate(),
            data.dateFrom.getHours(), // Preserve hours
            data.dateFrom.getMinutes(), // Preserve minutes
            data.dateFrom.getSeconds() // Preserve seconds
          )
        ).toISOString()
      : null;

    const dateTo = data.dateTo
      ? new Date(
          Date.UTC(
            data.dateTo.getFullYear(),
            data.dateTo.getMonth(),
            data.dateTo.getDate(),
            data.dateTo.getHours(), // Preserve hours
            data.dateTo.getMinutes(), // Preserve minutes
            data.dateTo.getSeconds() // Preserve seconds
          )
        ).toISOString()
      : null;

      const params = {
        statuses: data.statuses || "", 
        dateFrom: dateFrom,
        dateTo: dateTo,
        userId: data.userId || "",
        giftId: data.giftId || "",
      };

    console.log(params)

    handleSearch(params);
  };

  const handleResetSearch = () => {
    reset({ ...initialFilter });
    handleSearch({
      page: propsTable.page,
      size: propsTable.rowsPerPage,
      statuses: "",
      dateFrom: null,
      dateTo: null,
      userId: "",
      giftId: "",
    });
  };

  const { control, handleSubmit, reset } = methods;

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ px: 1 }}>
          <Grid container spacing={1} py="30px">
            <Grid item xs={1.5}>
              <Controller
                name="statuses"
                control={control}
                render={({ field }) => (
                  <TextField fullWidth label="Status" select {...field}>
                    {UserGiftStatusList.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={2}>
              <Controller
                name="userId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    placeholder={'Search by User ID'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={'eva:search-fill'}
                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={2}>
              <Controller
                name="giftId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    placeholder={'Search by Gift ID'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={'eva:search-fill'}
                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={'10px'}>
                <Controller
                  name="dateFrom"
                  key="dateFrom"
                  control={control}
                  render={({ field }) => (
                    <MobileDateTimePicker
                      {...field}
                      label="Từ ngày"
                      key="dateFrom"
                      inputFormat={FORMAT_DATE_NEWS}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Calendar />
                          </InputAdornment>
                        ),
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  )}
                />
              </Stack>
            </Grid>

            <Grid item xs={2}>
              <Stack spacing={'10px'}>
                <Controller
                  name="dateTo"
                  key="dateTo"
                  control={control}
                  render={({ field }) => (
                    <MobileDateTimePicker
                      {...field}
                      label="Đến ngày"
                      key="dateTo"
                      inputFormat={FORMAT_DATE_NEWS}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Calendar />
                          </InputAdornment>
                        ),
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  )}
                />
              </Stack>
            </Grid>

            <Stack direction="row" spacing={1} marginTop="10px" mx={2}>
              <LoadingButton type="submit" variant="contained" size="medium">
                Tìm kiếm
              </LoadingButton>
              <Button
                type="reset"
                variant="contained"
                color="inherit"
                size="medium"
                onClick={handleResetSearch}
              >
                Xóa
              </Button>
            </Stack>
          </Grid>
        </Card>
      </FormProvider>
    </>
  );
};

export default UserGiftListFilter;
