import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider } from 'src/common/components/hook-form';
import Iconify from 'src/common/components/Iconify';
import { PRE_ORDER_STATUS_LIST } from 'src/gift/common/constant';
import { FORMAT_DATE_NEWS } from 'src/store-admin/constants';

// ----------------------------------------------------------------------
export const initialFilter = {
  extendedSearch: '',
  statuses: [],
  fromDate: '',
  toDate: '',
};

export const GiftPreOrderTableToolbar = (props: { handleSearch: Function }) => {
  const { handleSearch } = { ...props };
  const methods = useForm({
    defaultValues: initialFilter,
  });
  const { control, handleSubmit, reset } = methods;

  const onSubmit = (data: any) => {
    handleSearch({
      extendedSearch: data.extendedSearch.length > 0 ? data.extendedSearch : undefined,
      preOrderStatus: data.statuses.length > 0 ? data.statuses.join(',') : undefined,
      fromDate: data.fromDate ? data.fromDate : undefined,
      toDate: data.toDate ? data.toDate : undefined,
    });
  };
  const handleResetSearch = () => {
    reset(initialFilter);
    handleSearch({});
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ px: 2 }}>
          <Grid container spacing={1} py="30px">
            <Grid item xs={2}>
              <Controller
                name="statuses"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Status"
                    select
                    SelectProps={{ multiple: true }}
                    defaultValue={[]}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                  >
                    {PRE_ORDER_STATUS_LIST.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="extendedSearch"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    placeholder={'Search by SĐT'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={'eva:search-fill'}
                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Controller
                name="fromDate"
                key="fromDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <MobileDateTimePicker
                    {...field}
                    label="Từ ngày"
                    key="fromDate"
                    inputFormat={FORMAT_DATE_NEWS}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Calendar />
                        </InputAdornment>
                      ),
                    }}
                    renderInput={(params) => (
                      <TextField {...params} error={!!error} fullWidth />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2.5}>
              <Controller
                name="toDate"
                key="toDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <MobileDateTimePicker
                    {...field}
                    label="Đến ngày"
                    key="toDate"
                    inputFormat={FORMAT_DATE_NEWS}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Calendar />
                        </InputAdornment>
                      ),
                    }}
                    renderInput={(params) => (
                      <TextField {...params} error={!!error} fullWidth />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={3} marginTop="10px" mx={4}>
                <LoadingButton type="submit" variant="contained" size="medium">
                  Tìm kiếm
                </LoadingButton>
                <Button
                  type="reset"
                  variant="contained"
                  color="inherit"
                  size="medium"
                  onClick={handleResetSearch}
                >
                  Xóa
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </FormProvider>
    </>
  );
};
