import { API_EXPORT_USER_GIFT, API_USER_GIFT_LIST } from 'src/common/constants/apis';
import { CommonResponseAPI } from 'src/common/constants/common.interfaces';
import axiosInstance from 'src/common/utils/axios';
import { IParamsOrderList } from './interface';

export const exportUserGiftList = (params: any) => {
  return axiosInstance.post(`${API_EXPORT_USER_GIFT}`, params, {
    responseType: 'blob',
  });
};

export const getUserGiftList = (params: IParamsOrderList) => {
  return axiosInstance.get<any, CommonResponseAPI<any>>(`${API_USER_GIFT_LIST}`, {
    params,
  });
};
